import React, { useEffect, Component } from "react";
import "../../styling/templates.css";
import Heder from "../../components/navigation-header";
import Footer from "../../components/footer";
import MainBanner from "../../images/esg_banner.jpg";
import MainBanner2 from "../../images/esg_banner2.jpg";

export default function Esg() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const dotStyle = {
    width: "8px",
    marginTop: "8px",
    height: "8px",
    borderRadius: "50%",
    backgroundColor: "#1b1464",
  };

  return (
    <div>
      <Heder />

      <center>
        <div className="section-background">
          <h2 className="esd-topic">
            Sustainability is our <br />
            strategic priority
          </h2>
        </div>
      </center>

      <div className="pc">
        <div
          style={{
            backgroundImage: `url(${MainBanner})`,
            backgroundSize: "cover",
            backgroundPosition: "center",
            width: "100%",
            height: "100vh",
            marginBottom: "100px",
          }}
        >
          <div className="overlayStyle4"></div>
        </div>
      </div>
      <div className="mobile">
        <div
          style={{
            backgroundImage: `url(${MainBanner2})`,
            backgroundSize: "cover",
            backgroundPosition: "center",
            width: "100%",
            height: "100vh",
            marginBottom: "100px",
          }}
        >
          <div className="overlayStyle4"></div>
        </div>
      </div>

      <center>
        <div className="section-background">
          <div className="pc">
            <table style={{ width: "100%", textAlign: "start" }}>
              <tr>
                <td style={{ width: "220px", verticalAlign: "top" }}>
                  <img
                    src="/images/ESG/esglogo.jpg"
                    style={{ width: "200px", marginTop: "6px" }}
                    alt="background-image"
                    loading="eager"
                  />
                </td>
                <td style={{ width: "auto", verticalAlign: "top" }}>
                  <p className="esg-para-italic">
                    BPPL is committed to sustainable business practices that are
                    responsible, ethical and social. This includes Integrating
                    sustainability in every aspect of our business.
                    <br />
                    <br />
                  </p>
                </td>
              </tr>
            </table>
          </div>

          <div className="mobile">
            <center>
              <img
                src="/images/ESG/esglogo.jpg"
                style={{ width: "200px", marginTop: "6px" }}
                alt="logo"
                loading="eager"
              />
              <br />
              <br />
              <p className="esg-para-italic">
                BPPL is committed to sustainable business practices that are
                responsible, ethical and social. This includes Integrating
                sustainability in every aspect of our business.
                <br />
                <br />
              </p>
            </center>
          </div>

          <table style={{ width: "100%", textAlign: "start" }}>
            <tr>
              <td
                className="image-banner"
                style={{ width: "50%", verticalAlign: "top" }}
              ></td>

              <td className="esg-right-column">
                <div className="text-30">
                  {/* Right column content */}
                  <div className="two-column-text">
                    <div>
                      <table style={{ width: "100%", textAlign: "start" }}>
                        <tr>
                          <td style={{ width: "150px" }}>
                            <img
                              src="/images/ESG/enviromment.png"
                              alt="enviornment-logo"
                              loading="lazy"
                              style={{
                                width: "120px",
                                marginTop: "10px",
                                marginBottom: "10px",
                              }}
                            />
                          </td>
                          <td style={{ width: "auto" }}>
                            <p className="esg-topic2"> Environmental </p>
                          </td>
                        </tr>
                      </table>
                    </div>

                    <p className="esg-600-text">
                      Invest in initiatives that control the availability of
                      natural resources, such as through renewable resources,
                      energy efficiency and upcycling. Developed innovative
                      technologies to reduce our impact on the planet.
                    </p>

                    <div style={{ marginTop: "20px" }}>
                      <img
                        style={{ height: "130px", marginBottom: "20px" }}
                        alt="awards-UKAS"
                        loading="lazy"
                        src="/images/ESG/ISO_14001_UKAS_2014-300x174.jpg"
                      />
                      <img
                        style={{ height: "150px" }}
                        alt="awards-GRS"
                        loading="lazy"
                        src="/images/ESG/GRS-2-150x150.jpg"
                      />
                    </div>

                    <h2 className="esg-topic3">PET recycling</h2>

                    <p>
                      <ul className="bullet-points">
                        <li>
                          We have recycled more than 571 million plastic bottles
                          up to date.
                        </li>
                        <li>
                          We recycle around 50 tons of other forms of plastic
                          such as Polypropylene, LDPE and HDPE each month. These
                          plastics are used in our brushware.
                        </li>
                      </ul>
                    </p>

                    <h2 className="esg-topic3">
                      Innovative and cutting-edge technology
                    </h2>

                    <p>
                      <ul className="bullet-points">
                        <li>
                          We use PET plastic waste as our main raw material,
                          eliminating the need for virgin PET materials in yarn
                          production. Recycled polyester requires 60% less
                          energy, reduces 82% GHG and 50% less water usage, and
                          is of the same quality as virgin polyester.
                        </li>
                        <li>
                          Making polyester yarn from flakes rather than chips
                          avoids the energy-intensive process of
                          de-polymerization and re-polymerization. This can
                          result in significant energy savings.
                        </li>
                        <li>
                          We use dope dyeing technology for higher quality dyed
                          yarn, which reduces water consumption and waste water
                          discharge.
                        </li>
                      </ul>
                    </p>

                    <h2 className="esg-topic3">Renewable energy</h2>

                    <p>
                      <ul className="bullet-points">
                        <li>
                          Our brush production site in Ingiriya, Sri Lanka, has
                          a 200KW biomass power plant that uses timber offcuts
                          to generate electricity and 800KWs of solar panels
                          mounted on factory roofs.
                        </li>
                        <li>
                          Solar panels at our Maugama and Horana facilities
                          produced approximately 1,050KW of electricity,
                          equivalent to 44% of their energy needs.
                        </li>
                      </ul>
                    </p>

                    <h2 className="esg-topic3">Waste water treatment</h2>

                    <p>
                      <ul className="bullet-points">
                        <li>
                          The waste water generated from our PET bottle washing
                          line is treated and reused by our wastewater treatment
                          system.
                        </li>{" "}
                        <br />
                      </ul>
                    </p>
                  </div>
                </div>
              </td>
            </tr>
          </table>

          <div className="mobile">
            <img
              src="/images/ESG/enviromment-1.jpg"
              alt="environment-background"
              loading="lazy"
              style={{
                width: "100%",
                marginTop: "10px",
                marginBottom: "10px",
              }}
            />
          </div>

          <table style={{ width: "100%", textAlign: "start" }}>
            <tr>
              <td className="esg-left-column">
                <div className="text-30-l">
                  {/* Left column content */}
                  <div className="two-column-text">
                    <div>
                      <table style={{ width: "100%", textAlign: "start" }}>
                        <tr>
                          <td style={{ width: "150px" }}>
                            <img
                              src="/images/ESG/social.png"
                              alt="social"
                              loading="lazy"
                              style={{
                                width: "120px",
                                marginTop: "30px",
                                marginBottom: "10px",
                              }}
                            />
                          </td>
                          <td style={{ width: "auto" }}>
                            <p className="esg-topic2"> Social </p>
                          </td>
                        </tr>
                      </table>
                    </div>

                    <p className="esg-600-text">
                      The lives of thousands of people all around the nation are
                      part of BPPL's commitment to sustainability.
                    </p>

                    <div style={{ marginLeft: "10px", marginTop: "20px" }}>
                      <p>
                        <ul className="bullet-points">
                          <li>
                            We create a happy and healthy working environment at
                            BPPL by allocating resources to various programs. We
                            do this by carrying out regular salary surveys,
                            competitive remuneration, career goals and planning,
                            job descriptions and gap analysis, and tailored
                            training programs.
                          </li>
                          <li>
                            We are an ISO 45001:2018 certified organisation,
                            which provides us with guidance for safe and healthy
                            workplaces by preventing work-related injuries and
                            improving performance.
                          </li>
                          <li>
                            At BPPL, we respect diversity, promote equal
                            opportunities, and welcome disabled persons. We are
                            committed to maximizing the performance of each
                            individual in the workplace.
                          </li>
                          <li>
                            We provide regular training and development for our
                            workforce.
                          </li>
                          <li>
                            We strengthen employee relations at the workplace
                          </li>
                        </ul>
                      </p>
                    </div>

                    <h2 className="esg-topic3">
                      Financial model for PET bottle collectors
                    </h2>

                    <div style={{ marginLeft: "10px" }}>
                      <p>
                        <ul className="bullet-points">
                          <li>
                            The bottle collection network helps low-income
                            communities, collecting waste bottles for an income.
                            In 2021-22, about 2,500 collectors benefited from an
                            indirect income, and 480 a direct income. BPPL
                            adopts strong ethical practices when collecting
                            bottles such as the non-use of child labour.
                          </li>
                          <li>
                            We help waste collectors to develop as social
                            entrepreneurs by providing guidance, free
                            consultation, infrastructure, and financial
                            assistance.
                          </li>{" "}
                          <br />
                        </ul>
                      </p>
                    </div>
                  </div>
                </div>
              </td>

              <td
                className="image-banner2"
                style={{ width: "50%", verticalAlign: "top" }}
              ></td>
            </tr>
          </table>

          <div className="mobile">
            <img
              src="/images/ESG/social-1.jpg"
              alt="mobile-social"
              loading="lazy"
              style={{
                width: "100%",
                marginTop: "10px",
                marginBottom: "10px",
              }}
            />
          </div>

          <table style={{ width: "100%", textAlign: "start" }}>
            <tr>
              <td
                className="image-banner3"
                style={{ width: "50%", verticalAlign: "top" }}
              ></td>

              <td className="esg-right-column">
                <div className="text-30">
                  {/* Right column content */}
                  <div className="two-column-text">
                    <div>
                      <table style={{ width: "100%", textAlign: "start" }}>
                        <tr>
                          <td style={{ width: "150px" }}>
                            <img
                              src="/images/ESG/govenance.png"
                              alt="govenance"
                              loading="lazy"
                              style={{
                                width: "120px",
                                marginTop: "10px",
                                marginBottom: "10px",
                              }}
                            />
                          </td>
                          <td style={{ width: "auto" }}>
                            <p className="esg-topic2">
                              {" "}
                              Governance&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{" "}
                            </p>
                          </td>
                        </tr>
                      </table>
                    </div>

                    <p className="esg-600-text">
                      Managing investments in a way that encourages moral and
                      efficient governance.
                    </p>

                    <div style={{ marginLeft: "10px" }}>
                      <p>
                        <ul className="bullet-points">
                          <li>
                            Promote integrity, transparency, accountability, and
                            responsiveness.
                          </li>
                          <li>
                            Ensure appropriate checks and balances between
                            Divisions and the Group.
                          </li>
                          <li>
                            Cultivate ethical business behaviour through a Code
                            of Business Conduct.
                          </li>{" "}
                          <br />
                        </ul>
                      </p>
                    </div>
                  </div>
                </div>
              </td>
            </tr>
          </table>

          <div className="mobile">
            <img
              src="/images/ESG/waste2valuapp.jpg"
              alt="waste-logo"
              loading="lazy"
              style={{ height: "auto", maxWidth: "100%" }}
            />

            <div>
              <img
                src="/images/ESG/waste2valuapp_logo.jpg"
                alt="waste-value-app"
                loading="lazy"
                style={{ height: "auto", maxWidth: "100%" }}
              />

              <p
                className="two-column-text-italic"
                style={{ marginLeft: "20px", marginTop: "20px" }}
              >
                BPPL passionately believes in promoting an environment in Sri
                Lanka that is committed to recycling. Out “Waste 2 Value” App
                encourage responsible plastic recycling among the public, and
                further recyclable waste collection efforts within the country.
              </p>

              <center>
                <div style={{ marginTop: "50px" }}>
                  {/* <a href="https://play.google.com/store/apps/details?id=com.plasticdisposalapp&pli=1"> */}
                  <img
                    src="/images/ESG/android.jpg"
                    alt="waste-android-app"
                    loading="lazy"
                    style={{ height: "50px", Width: "50px" }}
                  />
                  {/* </a> */}
                  <a href="https://apps.apple.com/lk/app/waste-2-value/id1556730694">
                    <img
                      src="/images/ESG/apple.jpg"
                      alt="waste-ios-app"
                      loading="lazy"
                      style={{ height: "50px", Width: "50px" }}
                    />
                  </a>

                  <a
                    href="https://www.ecospindles.com/download-waste-2-value-app/"
                    type="button"
                    class="btn btn-outline-dark btn-lg"
                    style={{
                      fontSize: "16px",
                      fontFamily: "Assistant', sans-serif",
                    }}
                  >
                    DOWNLOAD APP
                  </a>
                  <br />
                  <br />
                </div>
              </center>
            </div>
          </div>

          <div className="pc">
            <table style={{ width: "100%", marginTop: "50px" }}>
              <tr>
                <td style={{ width: "50%" }}>
                  <img
                    src="/images/ESG/waste2valuapp.jpg"
                    alt="waste-value-app"
                    loading="lazy"
                    style={{ height: "auto", maxWidth: "100%" }}
                  />
                </td>

                <td style={{ width: "50%" }}>
                  <div style={{ marginLeft: "30px" }}>
                    {/* Right column content */}
                    <div>
                      <img
                        src="/images/ESG/waste2valuapp_logo.jpg"
                        alt="waste"
                        loading="lazy"
                        style={{ height: "auto", maxWidth: "100%" }}
                      />

                      <p
                        className="two-column-text-italic"
                        style={{ marginLeft: "20px", marginTop: "20px" }}
                      >
                        BPPL passionately believes in promoting an environment
                        in Sri Lanka that is committed to recycling. Out “Waste
                        2 Value” App encourage responsible plastic recycling
                        among the public, and further recyclable waste
                        collection efforts within the country.
                      </p>

                      <center>
                        <div style={{ marginTop: "50px" }}>
                          {/* <a href="https://play.google.com/store/apps/details?id=com.plasticdisposalapp&pli=1"> */}

                          <img
                            src="/images/ESG/android.jpg"
                            alt="waste-value-android"
                            loading="lazy"
                          />
                          {/* </a> */}
                          <a href="https://apps.apple.com/lk/app/waste-2-value/id1556730694">
                            <img
                              src="/images/ESG/apple.jpg"
                              alt="waste-value-ios"
                              loading="lazy"
                            />
                          </a>

                          <a
                            href="https://www.ecospindles.com/download-waste-2-value-app/"
                            type="button"
                            class="btn btn-outline-dark btn-lg"
                            style={{
                              fontSize: "16px",
                              fontFamily: "Assistant', sans-serif",
                            }}
                          >
                            DOWNLOAD APP
                          </a>
                        </div>
                      </center>
                    </div>
                  </div>
                </td>
              </tr>
            </table>
          </div>
        </div>
      </center>

      <div
        style={{
          backgroundImage: `url("/images/ESG/Initiatives.jpg")`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          width: "100%",
        }}
      >
        <center>
          <div className="section-background">
            <br />
            <h2 className="esd-topic-white">
              Transparent auditable sustainability
            </h2>

            <div className="initiatives">
              <br />
              <div style={{ marginLeft: "40px" }}>
                <h2 className="esg-initiatives-topic-1">Group initiatives</h2>

                <table style={{ width: "100%", textAlign: "start" }}>
                  <tr>
                    <td style={{ width: "20px", verticalAlign: "top" }}>
                      <p>
                        <div style={dotStyle}></div>
                      </p>
                    </td>
                    <td style={{ width: "auto", verticalAlign: "top" }}>
                      <p className="esg-initiatives-topic-2">
                        <a href="https://www.ecospindles.com/ocean-waste-management/">
                          Coastal Plastic Waste Management Initiative
                        </a>
                      </p>
                    </td>
                  </tr>
                  <tr>
                    <td style={{ width: "20px", verticalAlign: "top" }}>
                      <p>
                        <div style={dotStyle}></div>
                      </p>
                    </td>
                    <td style={{ width: "auto", verticalAlign: "top" }}>
                      <p className="esg-initiatives-topic-2">
                        <a href="https://www.ecospindles.com/pilgrimage-waste-management/">
                          Pilgrimage Waste Management Initiative
                        </a>
                      </p>
                    </td>
                  </tr>
                  <tr>
                    <td style={{ width: "20px", verticalAlign: "top" }}>
                      <p>
                        <div style={dotStyle}></div>
                      </p>
                    </td>
                    <td style={{ width: "auto", verticalAlign: "top" }}>
                      <p className="esg-initiatives-topic-2">
                        <a href="https://www.ecospindles.com/other-initiatives/">
                          A Plastic Intervention In Arugam Bay
                        </a>
                      </p>
                    </td>
                  </tr>
                  <tr>
                    <td style={{ width: "20px", verticalAlign: "top" }}>
                      <p>
                        <div style={dotStyle}></div>
                      </p>
                    </td>
                    <td style={{ width: "auto", verticalAlign: "top" }}>
                      <p className="esg-initiatives-topic-2">
                        <a href="https://www.ecospindles.com/other-initiatives/">
                          A Mistreated Ocean's Cry For Help
                        </a>
                      </p>
                    </td>
                  </tr>
                  <tr>
                    <td style={{ width: "20px", verticalAlign: "top" }}>
                      <p>
                        <div style={dotStyle}></div>
                      </p>
                    </td>
                    <td style={{ width: "auto", verticalAlign: "top" }}>
                      <p className="esg-initiatives-topic-2">
                        <a href="https://www.ecospindles.com/other-initiatives/">
                          A PET Collector's Journey Amid A Raging <br />
                          Pandemic
                        </a>
                      </p>
                    </td>
                  </tr>
                  <tr>
                    <td style={{ width: "20px", verticalAlign: "top" }}>
                      <p>
                        <div style={dotStyle}></div>
                      </p>
                    </td>
                    <td style={{ width: "auto", verticalAlign: "top" }}>
                      <p className="esg-initiatives-topic-2">
                        <a href="https://www.ecospindles.com/other-initiatives/">
                          500 Schools, 500 Futures
                        </a>
                      </p>
                    </td>
                  </tr>
                  <tr>
                    <td style={{ width: "20px", verticalAlign: "top" }}>
                      <p>
                        <div style={dotStyle}></div>
                      </p>
                    </td>
                    <td style={{ width: "auto", verticalAlign: "top" }}>
                      <p className="esg-initiatives-topic-2">
                        <a href="https://www.ecospindles.com/other-initiatives/">
                          Community Empowerment; With The Asia <br />
                          Foundation And Coca-Cola
                        </a>
                      </p>
                    </td>
                  </tr>
                </table>
                <div style={{ marginTop: "10px" }}>
                  <div>
                    <a
                      href="https://www.ecospindles.com/other-initiatives/"
                      target="_blank"
                      className="btn custom-button-about3 "
                    >
                      VIEW ALL INITIATIVES
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </center>
      </div>

      <Footer />
    </div>
  );
}
